// typescript file: managed_consent.ts
// it exports a function that takes 2 arguments: a string ('vendor'), and a function ('callback').
// it checks if the vendor == 'example' and if so, executes the function immediately. Otherwise it does not execute the function.
// USAGE: managed_consent(function() { console.log('hello world') }, 'example')

// https://developers.didomi.io/cmp/web-sdk/reference#isconsentrequired
// https://developers.didomi.io/cmp/web-sdk/reference/api#getobservableonuserconsentstatusforvendor-vendorid

/**
 * Managed Consent helper
 * @param vendor The didomi SDK vendor code, e.g. 'facebook' or 'c:datadog-892VRMnf'
 * @param callback code to run, IF the user has given consent to the vendor
 */

export function managedConsent(vendor: string, callback: () => void): null {
  // When the Didomi SDK is not on the page, CMP is off, so run it right away
  if (document.querySelector('script#DidomiMain') === null) {
    callback();
    return null;
  }
  let { didomiOnReady } = window;
  didomiOnReady = didomiOnReady || [];
  didomiOnReady.push((Didomi) => {
    if (Didomi.isConsentRequired()) {
      // Consent is required: your visitor is from the EU or you are an EU company
      // Only enable the vendor when consent is given
      Didomi.getObservableOnUserConsentStatusForVendor(vendor)
        .first()
        .filter((status) => status !== undefined)
        .subscribe((consentStatus) => {
          if (consentStatus === true) {
            // The user has given consent to the vendor
            // console.debug(`Managed Consent: granted for ${vendor}`);
            callback();
          }
        });
    } else {
      // Consent is not required, enable your vendor immediately
      // console.debug(`Managed Consent: default opt-in for ${vendor}`);
      callback();
    }
  });
  return null;
}
