import '~/lib/datadog'; // Init Datadog RUM and Logs

// Libraries with plugin ecosystems
import './jquery-setup';
import './moment-setup';
import './bootstrap-setup';

// Everything else
import React from 'react';
import 'react-dates/initialize';

window.React = React; // Required for react-ziggeo

declare global {
  interface Window {
    /** @deprecated prefer explicit import `import Module from 'package-name';` */
    React: typeof React;
  }
}
