import moment from 'moment';

window.moment = moment;

declare global {
  interface Window {
    /** @deprecated prefer explicit import `import Module from 'package-name';` */
    moment: typeof moment;
  }
}
