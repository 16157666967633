import type { BadgeProps } from '@workramp/ui';
import type { EventStatusNames } from '~/types';
import { I18n } from '~/common/explicit-exports';

export const EVENT_STATUS_NAMES: Record<EventStatusNames, string> = {
  absent: I18n.t('common.event_status.absent'),
  attended: I18n.t('common.event_status.attended'),
  invited: I18n.t('common.event_status.invited'),
  registered: I18n.t('common.event_status.registered'),
  unregistered: I18n.t('common.event_status.unregistered'),
  waitlisted: I18n.t('common.event_status.waitlisted'),
};

export const EVENT_STATUS_BADGE_COLORS: Record<EventStatusNames, BadgeProps['variant']> = {
  absent: 'danger',
  attended: 'success',
  invited: 'neutral',
  registered: 'success',
  unregistered: 'neutral',
  waitlisted: 'warning',
};
