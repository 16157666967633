import jQuery from 'jquery';

window.jQuery = jQuery;
window.$ = jQuery;

declare global {
  interface Window {
    /** @deprecated prefer explicit import `import Module from 'package-name';` */
    jQuery: typeof jQuery;
    /** @deprecated prefer explicit import `import Module from 'package-name';` */
    $: typeof jQuery;
  }
}
