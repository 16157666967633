let hasSeenOverrideMsg = false;

const featureFlagLocalOverride = (name) => {
  if (window.env !== 'development') return null;

  const { searchParams } = new URL(document.location);
  const overriddenOn = searchParams.get('ff_on')?.split(',') || [];
  const overriddenOff = searchParams.get('ff_off')?.split(',') || [];

  // Memo so log message is only shown once per page load
  if (!hasSeenOverrideMsg) {
    [
      ['ON', overriddenOn],
      ['OFF', overriddenOff],
    ].forEach((params) => {
      if (params[1].length !== 0) {
        console.log(
          `%c The following feature flags are being locally overridden as ${params[0]}: ${params[1]
            .map((ff) => `\n - ${ff}`)
            .join('')}`,
          'font-size:16px;color:lime;background:black;',
        );
      }
    });

    hasSeenOverrideMsg = true;
  }

  const isOverriddenOn = overriddenOn.includes(name);
  const isOverriddenOff = overriddenOff.includes(name);

  if (!isOverriddenOn && !isOverriddenOff) {
    return null;
  }

  return { name, value: isOverriddenOn };
};

/**
 * Helper for determining whether a feature flip is enabled.
 * */
const isFeatureEnabled = (name) => {
  if (window.env === 'development') {
    const localOverride = featureFlagLocalOverride(name);
    if (localOverride) return localOverride.value;
  }

  const knownInternalUser = typeof getCurrentUser === 'function' && getCurrentUser();
  const anonymousInternalUserFeatureFlips =
    typeof getEnterpriseFeatureFlips === 'function' && window.getEnterpriseFeatureFlips();
  const knownExternalUser = typeof getExternalUser === 'function' && getExternalUser();
  const anonymousExternalUserFeatureFlips =
    typeof getAcademyFeatureFlips === 'function' && window.getAcademyFeatureFlips();

  if (knownInternalUser?.enterprise) {
    return knownInternalUser.enterprise.feature_flips[name];
  }
  if (anonymousInternalUserFeatureFlips) {
    return anonymousInternalUserFeatureFlips[name];
  }
  if (knownExternalUser?.enterprise) {
    return knownExternalUser.enterprise.featureFlips[name];
  }
  if (anonymousExternalUserFeatureFlips) {
    return anonymousExternalUserFeatureFlips[name];
  }
  console.warn('No feature flags associated with current user.');
  return false;
};

window.isFeatureEnabled = isFeatureEnabled;
export default isFeatureEnabled;
