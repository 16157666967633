import TimeMe from 'timeme.js';

const USER_ACTIVITY_WHITE_LISTED_URLS = [
  /^\/task_assignments\/[\w-]+(\/path_assignment_id\/[\w-]+)?$/,
  /^\/guides\/[\w-]+\/tasks\/[\w-]+$/,
  /^\/challenge_assignments\/[\w-]+$/,
];

function userActivityWhiteListedUrl(pathname: string) {
  return USER_ACTIVITY_WHITE_LISTED_URLS.some((whiteListedUrl) => whiteListedUrl.test(pathname));
}

function resolveUserType() {
  const internalUser = window.getCurrentUser();
  if (internalUser !== null) {
    return { userIdField: 'user_id', userId: internalUser.id };
  }

  const externalUser = window.getExternalUser();
  if (externalUser !== null) {
    return { userIdField: 'external_user_id', userId: externalUser.id };
  }

  return null;
}

function publishTimer() {
  const userObject = resolveUserType();
  if (!userObject) return;

  const postData = new FormData();
  const timeSpentOnTask = TimeMe.getTimeOnCurrentPageInSeconds();

  postData.append('content_uri', TimeMe.currentPageName);
  postData.append('time_spent', timeSpentOnTask);
  postData.append(userObject.userIdField, userObject.userId);

  const csrfParam = document.querySelector('meta[name=csrf-param]')?.getAttribute('content');
  const csrfTokenElement = document.head.querySelector('[name=csrf-token][content]') as HTMLMetaElement | null;
  const csrfToken = csrfTokenElement?.content;

  if (csrfParam && csrfToken) {
    postData.append(csrfParam, csrfToken);
  }

  navigator.sendBeacon('/api/url_activity', postData);
}

function publishTimerAndResetActivityTracking() {
  TimeMe.stopTimer();
  publishTimer();
  TimeMe.resetAllRecordedPageTimes();
  window.removeEventListener('visibilitychange', handleVisibilityChange);
}

function startTimerAndActivityTracking(pathname: string) {
  // Before TimeMe is initialized, the currentPageName === "default-page-name"
  // after we initialize TimeMe once, the currentPageName will be whatever we subsequently set it to
  if (TimeMe.currentPageName === 'default-page-name') {
    TimeMe.initialize({
      currentPageName: pathname,
      idleTimeoutInSeconds: 60,
    });
  } else {
    TimeMe.setCurrentPageName(pathname);
    TimeMe.startTimer();
  }

  window.addEventListener('visibilitychange', handleVisibilityChange);
}

const handleVisibilityChange = () => {
  const { pathname } = window.location;

  if (document.visibilityState === 'visible') {
    return;
  }

  publishTimerAndResetActivityTracking();

  if (userActivityWhiteListedUrl(pathname)) {
    window.addEventListener('visibilitychange', handleVisibilityChange);
  }
};

// Handle client-side route changes
export function handleLocationChange(pathname: string) {
  if (userActivityWhiteListedUrl(TimeMe.currentPageName)) {
    publishTimerAndResetActivityTracking();
  }

  if (userActivityWhiteListedUrl(pathname)) {
    startTimerAndActivityTracking(pathname);
  }
}

export function initializeActivityTracking() {
  const { pathname } = window.location;

  if (!userActivityWhiteListedUrl(pathname)) return;

  TimeMe.initialize({
    currentPageName: pathname,
    idleTimeoutInSeconds: 60,
  });

  window.addEventListener('visibilitychange', handleVisibilityChange);
}
