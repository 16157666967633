import _ from 'underscore';

window.UsersAndGroupsToAddStore = function (initialUsersAndGroups, initialCallbacks) {
  this.usersAndGroups = initialUsersAndGroups || [];
  this.currentUserOrGroupToAdd = null;
  this.callbacks = initialCallbacks || [];
};

UsersAndGroupsToAddStore.prototype.getAll = function () {
  return this.usersAndGroups;
};

UsersAndGroupsToAddStore.prototype.getAllForSave = function () {
  if (this.currentUserOrGroupToAdd && this.currentUserOrGroupToAdd.trim()) {
    this.usersAndGroups.push({
      text: this.currentUserOrGroupToAdd.trim(),
      label: this.currentUserOrGroupToAdd.trim(),
    });
  }

  return _.uniq(this.usersAndGroups, (userAndGroup) => userAndGroup.label);
};

UsersAndGroupsToAddStore.prototype.getAllEmailsForSave = function () {
  const allUsers = this.getAllForSave();
  return _.map(allUsers, (user) => user.label);
};

UsersAndGroupsToAddStore.prototype.getCurrent = function () {
  return this.currentUserOrGroupToAdd;
};

UsersAndGroupsToAddStore.prototype.add = function (toAdd) {
  this.usersAndGroups.push(toAdd);
  this.currentUserOrGroupToAdd = null;
  this.notify();
};

UsersAndGroupsToAddStore.prototype.remove = function (toRemove) {
  this.usersAndGroups = _.filter(this.usersAndGroups, (userOrGroup) => userOrGroup.label != toRemove);
  this.notify();
};

UsersAndGroupsToAddStore.prototype.setCurrent = function (toAdd) {
  this.currentUserOrGroupToAdd = toAdd;
};

UsersAndGroupsToAddStore.prototype.clear = function () {
  this.usersAndGroups = [];
  this.currentUserOrGroupToAdd = null;
};

UsersAndGroupsToAddStore.prototype.addChangeListener = function (callback) {
  this.callbacks.push(callback);
};

UsersAndGroupsToAddStore.prototype.notify = function () {
  for (let i = 0; i < this.callbacks.length; i++) {
    this.callbacks[i](this.getAllForSave());
  }
};
