/**
 * This file is the entry point for the application.
 * It is responsible for mounting our "root" React components
 * that are defined in the registry.
 *
 * The `renderReactComponent` function is exposed to the window object
 * so that it can be called from the `render_react_component()` helper
 * inside the Rails views.
 *
 * It is unlikely that you will need to modify this file.
 */

import '~/globals';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { AppProvider } from '~/providers';
import { REGISTRY } from '~/registry';

declare global {
  interface Window {
    renderReactComponent: (name: string, id: string, props: any) => void;
  }
}

function renderReactComponent(name: string, id: string, props: any) {
  const Component = REGISTRY[name];

  const rootElement = document.getElementById(id);

  if (rootElement && Component) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
      <AppProvider>
        <Suspense fallback={null}>
          <Component {...props} />
        </Suspense>
      </AppProvider>,
    );
  }
}

window.renderReactComponent = renderReactComponent;
