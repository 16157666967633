import type { BadgeProps, SemanticColorNames } from '@workramp/ui';
import type { AssignmentStatusNames } from '~/types';
import { I18n } from '~/common/explicit-exports';

export enum AssignmentStatusEnum {
  IN_PROGRESS = 'in_progress',
  OVERDUE = 'overdue',
  COMPLETED = 'completed',
  NOT_STARTED = 'not_started',
  NEEDS_GRADING = 'needs_grading',
  FAILED = 'failed',
  PASSED = 'passed',
}

export const ASSIGNMENT_STATUS_NAMES: Record<AssignmentStatusNames, string> = {
  not_started: I18n.t('common.assignment_status.not_started'),
  in_progress: I18n.t('common.assignment_status.in_progress'),
  needs_grading: I18n.t('common.assignment_status.needs_grading'),
  completed: I18n.t('common.assignment_status.completed'),
  failed: I18n.t('common.assignment_status.failed'),
  overdue: I18n.t('common.assignment_status.overdue'),
  passed: I18n.t('common.assignment_status.passed'),
};

export const ASSIGNMENT_STATUS_BADGE_COLORS: Record<AssignmentStatusNames, BadgeProps['variant']> = {
  not_started: 'neutral',
  in_progress: 'neutral',
  needs_grading: 'warning',
  completed: 'success',
  failed: 'danger',
  overdue: 'danger',
  passed: 'success',
};

export const ASSIGNMENT_STATUS_TEXT_COLORS: Record<AssignmentStatusNames, SemanticColorNames | undefined> = {
  not_started: undefined,
  in_progress: undefined,
  needs_grading: undefined,
  completed: 'success',
  failed: 'danger',
  overdue: 'danger',
  passed: 'success',
};
