window.iOSOpenUniversalLink = function (url) {
  //  clear the body while the next page loads
  document.body.innerHTML = '<img src="/images/logo_new.png" style="width:90%; padding: 50% 5%">';
  //  load the requested URL

  //  Hack to fix BUG in iOS app where an extra parenthesis is added to end of url
  //  TODO: deploy new iOS app version (fix is committed to master in repo)
  url = url.slice(-1) === ')' ? url.slice(0, -1) : url;

  window.location.href = url;
};

window.iOSAppNotification = function () {
  //  triggered whenever the app is opened
};
