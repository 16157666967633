import type { Consumer } from '@rails/actioncable';
import { createContext, useContext, type ReactNode } from 'react';
import { consumer } from '~/lib/rails-actioncable';

export const ActionCableContext = createContext<Consumer | null>(null);

type Props = {
  children: ReactNode;
};

export function ActionCableProvider({ children }: Props) {
  return <ActionCableContext.Provider value={consumer}>{children}</ActionCableContext.Provider>;
}

export function useActionCable() {
  const contextConsumer = useContext(ActionCableContext);

  if (!contextConsumer) {
    throw new Error('useActionCableConsumer must be used within an ActionCableProvider');
  }

  return contextConsumer;
}
