import AssetPickerTypes from '~/components/common/asset-picker/asset-picker-types';

export const academyCustomCSS = {
  header: {
    navigation: 'AL_Custom_NavContentWrapper',
    logoLink: 'AL_Custom_NavLogo',
    customLinks: 'AL_Custom_NavCustomLinksList',
    customLink: 'AL_Custom_NavCustomLink',
    searchBar: 'AL_Custom_NavSearchBar',
    searchBarIconMobile: 'AL_Custom_NavSearchTriggerMob',
    searchBarCloseIconMobile: 'AL_Custom_NavSearchCloseMob',
    menuButtonMobile: 'AL_Custom_NavMenuBtnMob',
    closeMenuButtonMobile: 'AL_Custom_NavMenuCloseMob',
    loginLinkMobile: 'AL_Custom_NavLoginBtnMob',
    bannerMainHeading: 'AL_Custom_BannerMainHeading',
    bannerSubHeading: 'AL_Custom_BannerSubHead',
    bannerStatsWrapper: 'AL_Custom_BannerStatsWrapper',
    bannerStat: 'AL_Custom_BannerStat',
    bannerCta: 'AL_Custom_BannerCta',
    navImageBackground: 'AL_Custom_NavBg',
    menuBackdropMobile: 'AL_Custom_MenuBackdropMob',
    accountMenuIcon: 'AL_Custom_NavAccountMenuTrigger',
    loginButton: 'AL_Custom_NavLoginButton',
    accountMenuPopup: 'AL_Custom_NavAcctPopupMenu',
    accountMenuPopupItem: 'AL_Custom_NavAcctPopupMenuItem',
    goBackToLink: 'AL_Custom_NavGoToLink',
  },
  tabBar: {
    bar: 'AL_Custom_TabBar',
    tab: 'AL_Custom_TabBarTab',
  },
  footer: {
    bar: 'AL_Custom_Footer',
    links: 'AL_Custom_FooterLinkList',
    link: 'AL_Custom_FooterLinkListItem',
    poweredBy: 'AL_Custom_PoweredBy',
  },
  breadcrumbs: {
    breadcrumbBar: 'AL_Custom_BreadcrumbBar',
    breadcrumb: 'AL_Custom_Breadcrumb',
  },
  cardList: {
    cardsContainer: 'AL_Custom_CardContainer',
    card: 'AL_Custom_SingleCard',
    cardContentInner: 'AL_Custom_CardContentInner',
    cardImage: 'AL_Custom_CardImage',
    cardBody: 'AL_Custom_CardBody',
    cardFooter: 'AL_Custom_CardFooter',
    cardTitle: 'AL_Custom_CardTitle',
    cardDescription: 'AL_Custom_CardDescription',
    cardMetas: 'AL_Custom_CardMetas',
    cardContentTypeLabel: 'AL_Custom_ContentTypeLabel',
    cardItemsCount: 'AL_Custom_ItemsCount',
    cardDecorativeShadow: 'AL_Custom_CardShadows',
    sectionTitle: 'AL_Custom_OverviewSectionTitle',
    cardCta: 'AL_Custom_CardCta',
    cardType: {
      [AssetPickerTypes.RESOURCE]: `AL_Custom_SingleCard--${AssetPickerTypes.RESOURCE}`,
      [AssetPickerTypes.GUIDE]: `AL_Custom_SingleCard--${AssetPickerTypes.GUIDE}`,
      [AssetPickerTypes.ACADEMY_EVENT]: `AL_Custom_SingleCard--${AssetPickerTypes.ACADEMY_EVENT}`,
      [AssetPickerTypes.ACADEMY_CERTIFICATION]: `AL_Custom_SingleCard--${AssetPickerTypes.ACADEMY_CERTIFICATION}`,
      [AssetPickerTypes.ACADEMY_PATH]: `AL_Custom_SingleCard--${AssetPickerTypes.ACADEMY_PATH}`,
      [AssetPickerTypes.ACADEMY_COLLECTION]: `AL_Custom_SingleCard--${AssetPickerTypes.ACADEMY_COLLECTION}`,
    },
  },
  profile: {
    languageSelector: 'AL_Custom_ProfileLanguageSetting',
  },
  overview: {
    overviewContent: 'AL_Custom_PCOverviewContent',
    summaryBox: 'AL_Custom_PCSummaryBox',
    summaryInnerWrapper: 'AL_Custom_PCInnerSummaryBox',
    summaryImage: 'AL_Custom_PCSummaryImage',
    moduleBoxes: 'AL_Custom_PCModuleBoxes',
    title: 'AL_Custom_PCTitle',
    moduleCount: 'AL_Custom_PCModuleCount',
    duration: 'AL_Custom_PCDuration',
    summaryCta: 'AL_Custom_PCSummaryCta',
    moduleBoxOuterWrapper: 'AL_Custom_PCModuleBoxOuterWrapper',
    moduleBox: 'AL_Custom_PCModuleBox',
    moduleBoxInnerWrapper: 'AL_Custom_PCModuleInnerWrapper',
    moduleTitle: 'AL_Custom_PCModuleTitle',
    moduleDescription: 'AL_Custom_PCModuleDescription',
    moduleDuration: 'AL_Custom_PCModuleDuration',
    modulePassingStatus: 'AL_Custom_PCModulePassingStatus',
    guideSection: 'AL_Custom_PCModuleGuideSection',
    guideTaskTitleWrapper: 'AL_Custom_PCModuleGuideTaskTitleWrapper',
    guideTaskTitle: 'AL_Custom_PCModuleGuideTaskTitle',
    contentToggledContent: 'AL_Custom_PCToggledContent',
    contentToggleWrapper: 'AL_Custom_PCShowHideToggleWrapper',
    contentToggle: 'AL_Custom_PCShowHideToggle',
    dottedLine: 'AL_Custom_PCPathLine',
    completionStatusDot: 'AL_Custom_CompletionStatusDot',
    earnedIcon: 'AL_Custom_PCEarnedIcon',
    guideOverviewOutlineWrapper: 'AL_Custom_GOOutlineWrapper',
    guideOverviewTitle: 'AL_Custom_GOHeading',
    guideOverviewHeaderContainer: 'AL_Custom_GOHeaderContainer',
    guideOverviewSectionContainer: 'AL_Custom_GOSectionContainer',
    guideOverviewSectionTitle: 'AL_Custom_GOSectionTitle',
    guideOverviewSectionTasksContainer: 'AL_Custom_GOSectionTaskContainer',
    guideOverviewSectionTaskTitle: 'AL_Custom_GOSectionTask',
  },
  certificateTemplate2: {
    certWrapper: 'AL_Custom_Cert2Wrapper',
    background: 'AL_Custom_Cert2Background',
    sidebar: 'AL_Custom_Cert2Sidebar',
    badge: 'AL_Custom_Cert2Badge',
    sideBarCertTitle: 'AL_Custom_SidebarCert2Title',
    certId: 'AL_Custom_Cert2Id',
    logo: 'AL_Custom_Cert2Logo',
    main: 'AL_Custom_Cert2Main',
    learnerName: 'AL_Custom_Cert2LearnerName',
    completionInfo: 'AL_Custom_Cert2CompletionInfo',
    completedMsg: 'AL_Custom_Cert2CompletedMsg',
    mainCertTitle: 'AL_Custom_MainCert2CertTitle',
    awardedAtDate: 'AL_Custom_Cert2AwardedAtDate',
    description: 'AL_Custom_Cert2CertDescription',
    footerContainer: 'AL_Custom_Cert2FooterContainer',
    issuerName: 'AL_Custom_Cert2IssuerName',
    expiresAtDate: 'AL_Custom_Cert2ExpiresAtDate',
    issuerTitle: 'AL_Custom_Cert2IssuerTitle',
  },
  templates: {
    common: 'AL_Custom_Template',
    preview: 'AL_Custom_Template_Trainings',
    certifications: 'AL_Custom_Template_Certifications',
    certification: 'AL_Custom_Template_Certification',
    collections: 'AL_Custom_Template_Collections',
    events: 'AL_Custom_Template_Events',
    event: 'AL_Custom_Template_Event',
    paths: 'AL_Custom_Template_Paths',
    path: 'AL_Custom_Template_Path',
    my_trainings: 'AL_Custom_Template_MyTrainings',
    trainings: 'AL_Custom_Template_Trainings',
    training: 'AL_Custom_Template_Training',
  },
};
